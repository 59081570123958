import React from 'react';
import MainLayout from '../layouts/MainLayout';
import { Content } from '../pageComponents/Custom/Content';
import { Seo } from '../components/Seo';

const CustomPageTemplate = ({ pageContext }: any) => {
  const seo = pageContext.seo;
  const title = pageContext.title;

  return (
    <MainLayout>
      <Seo
        title={seo.seoTitle ? seo.seoTitle : title}
        description={seo.seoDescription ? seo.seoDescription : ''}
      />

      <Content data={pageContext} />
    </MainLayout>
  );
};
export default CustomPageTemplate;
